div.ui.layout-menu.menu {
  background: transparent;
  border: none;
  box-shadow: none;
  font-size: $font-body;

  a.item {
    font-weight: normal;
    color: $color-inactive-gray;
    background: transparent;
    &:hover {
      background: transparent;
      color: $color-primary;
    }
    &:focus {
      background: transparent;
      font-weight: 600;
      color: $color-font-black;
      &:hover {
        background: transparent;
      }
    }
  }

  a.item.active {
    background: none;
    font-weight: $font-weight-bold;
    color: $color-font-black;
    &:hover {
      background: none;
      color: $color-font-black;
    }
  }

  // remove padding left-right for logo/icon profile of navbar
  div.item {
    &.pd-right0 {
      padding-right: 0;
    }
    &.pd-left0 {
      padding-left: 0;
    }

    &.align-self-End {
      align-self: flex-end;
    }
  }
}
