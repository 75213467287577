$font-weight-bold: bold; // 700
$font-weight-initial: initial; //400

$font-large-title: 32px;
$font-title1: 24px;
$font-title2: 20px;
$font-body: 16px;
$font-secondary: 15px;
$font-caption1: 12px;

$font-icon: 20px;

span {
  &.font--secondary {
    font-size: $font-secondary;
    margin-top: 0 !important;
  }

  &.color--empty {
    color: $color-font-empty;
    font-weight: 500;
  }

  &.user-nameProfile {
    font-size: 16px;
    margin-right: 5px;
  }

  &.font--error {
    color: $color-font-error;
  }

  &.font--warning {
    color: $color-font-warning;
  }
}

p, div {
  &.font--body {
    font-size: $font-body;
  }

  &.font-caption1 {
    font-size: $font-caption1;
  }

  &.font-primary {
    color: $color-font-primary;
  }

  &.font--error {
    font-size: $font-body;
    color: $color-font-error;
  }

  &.font--warning {
    font-size: $font-body;
    color: $color-font-warning;
  }

  &.color--darkgray {
    color: $color-font-empty;
  }

  &.mg0 {
    margin: 0;
  }

  &.weight--initial {
    font-weight: $font-weight-initial;
  }

  &.mg--bottom {
    margin-bottom: 1em;
  }

  &.mg--top {
    margin-top: 1em;
  }
}

div.ui.header {
  // font size
  &.font--title1 {
    font-size: $font-title1;
  }

  &.font--title2 {
    font-size: $font-title2;
  }

  &.font--body {
    font-size: $font-body;
  }

  &.cursor--pointer {
    cursor: pointer;
  }

  &.color--gray {
    color: $color-secondary-active-darker;
  }

  // font weight
  &.weight--bold {
    font-weight: $font-weight-bold;
  }

  &.weight--normal {
    font-weight: normal;
  }

  // maegin amd padding
  &.mg0 {
    margin: 0 !important;
  }

  &.font-weight {
    font-weight: normal;
  }

  &.pd--top--bottom {
    padding: 0.6em 0;
  }

  &.pd--05 {
    padding: 0.5em;
  }
}

// label can attach to a content segment in relation component
.ui.top.attached.label:first-child + :not(.attached) {
  margin-top: 1em !important;
}

div.ui.header {
  &.header__title {
    font-size: $font-title1;
    font-weight: $font-weight-bold;
    line-height: 40px;
  }
  &.text-center {
    text-align: center;
  }
}

p.role-permit {
  font-style: normal;
  font-weight: normal;
  font-size: 1.0714em;
  line-height: 1.6em !important;

  &--desc {
    color: #c4c4c4;
    margin-bottom: 0.5333em !important;
  }

  &--amount {
    padding: 0.2667em 1.0667em;
    text-align: center;
    border-radius: 0.5333em;
    border: 1px solid black;
    width: 10vw;
  }
}

p {
  &.desc-user {
    color: $color-inactive-gray;
    font-size: 1.0714em;
    text-align: left;
  }

  &.name-role {
    font-size: 0.8571em;
    line-height: 1.3333em;
    align-content: center;
    text-align: center;
  }

  &.text--error {
    color: red;
    font-size: $font-caption1;
    text-align: left;
  }

  &.text-lastUpdate {
    color: $color-font-empty;
    padding-left: 15px;
    margin-top: 10px;
  }

  &.text-addStyle {
    font-size: $font-caption1;
    color: $color-font-empty;
  }

  &.text--description {
    width: 70%;
    color: $color-font-empty;
    line-height: 24px;
    margin-bottom: 20px;
  }

  &.username_logout {
    display: inline-block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

div.text--validate-error {
  color: red;
  font-size: $font-caption1;
  text-align: left;
  padding-top: 5px;
}

.word-break {
  word-break: break-word;
}

span.colorName {
  position: relative;
  left: 25px;
  top: 1px;
  color: $color-secondary-active;
}

.word-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-link {
  &:hover {
    text-decoration: underline;
    color: $color-font-green !important;
    text-decoration-color: $color-font-green !important;
  }
}

.text-description {
  font-size: 16px;
  color: $color-font-empty;
}