div.ui.segment {
  &.segment__custom {
    &--height40per {
      height: 40%;
    }

    &--height45per {
      height: 45%;
    }
  }
}

div.ui.basic.segment.segment-logo {
  top: 42px;
  left: 108px;
}

// login segment
div.ui.segment.login-form-segment {
  @media only screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  padding: 50px;
  padding-left: 70px;
  padding-right: 70px;
  background: $segment-background;
  box-shadow: $segment-box-shadow;
  border-radius: 8px;
}

div.ui.segment.segment-change-password {
  @media only screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  padding: 50px;
  padding-left: 70px;
  padding-right: 70px;
  margin: auto;
  margin-top: 5%;
  background: $segment-background;
  box-shadow: $segment-box-shadow;
  border-radius: 8px;
}

div.ui.segment.pushable.directory__segment--content {
  border: none;
  overflow: hidden;

  div.ui.segment.active.tab {
    // margin-top: 40px;
    border: none;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;

    div.ui.grid {
      div.row {
        div.thirteen.wide.column {
          padding: 0px;
        }

        div.three.wide.column {
          padding: 0px;
        }
      }
    }
  }
}

div.ui.vertical.labeled.icon.ui.overlay.right.thin.visible.sidebar.sidebar--width308.menu {
  width: 370px;
  overflow-x: hidden;
  overflow-y: hidden !important;
}

div.ui.segment.segment-nonePending {
  padding: 0px;
}

div.ui.segment.segment-addPadding-left-right {
  padding-left: 20px;
  padding-right: 20px;
}

// Add directory
div.ui.segment.segment-addCard {
  border: 2px dashed $segment-add-directory-color;
  box-shadow: none;
  font-size: 20px;
  color: $segment-add-directory-color;
  text-align: center;
  height: 48px;
  padding: 12px;
  border-radius: 8px;
  background-color: transparent;

  i.plus.icon {
    -webkit-text-stroke: 2px $segment-add-directory-hover;
  }

  &:hover {
    background-color: $segment-add-directory-color;
    color: $segment-add-directory-hover !important;
    border: none;
    cursor: pointer;

    i.plus.icon {
      -webkit-text-stroke: 2px $segment-add-directory-color;
      color: $segment-add-directory-hover;
    }

    // svg.svg-icon.svg-icon__add-without-border{
    //   #icon-add-plus {
    //     fill: #fff;
    //   }
    // }
  }
}

// img
div.ui.basic.segment.img-center {
  top: 30px;
}

// child accordion in directory page
div.segment {
  &.segment__menu--withborder {
    border: 1px solid $segment-border;
    border-radius: 0.5em;
    color: $segment-border;
    padding: 1em;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      color: $segment-background;
      background: $segment-border;
      box-shadow: $segment-box-shadow;
    }

    &.segment-selected {
      color: $segment-background;
      background: $segment-border;
    }
  }
}

div.ui.segment.segment__table--height300px {
  max-height: calc(100vh - 420px);
  overflow-y: auto;
  padding: 0px;
  margin-top: 1em;
}

div.ui.segment.segment--bg-top-sidebar {
  margin: 0px;
  border: none;
  box-shadow: none;
  background-color: $color-active-dark-green;
  height: 24px;
  border-radius: 0px;
  padding: 0px;
}

div.ui.segment {
  &__sidebar {
    height: calc(100vh - 170px);
    box-shadow: $segment-box-shadow !important;
    border-radius: 8px 8px 0 0;
    overflow-y: hidden !important;
  }

  &__button-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  &.segment--loading {
    padding: 0 1em 0 0;
  }

  &.segment--center {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // max-width: initial;
    // flex: auto;
    align-self: center;

    &.height75p {
      min-height: 75%;
    }

    &.height17em {
      min-height: 17em;
    }

    &.height-450px {
      min-height: calc(100vh - 500px);
    }
  }

  &.segment--fix {
    min-height: calc(100vh - 500px);
    max-height: calc(100vh - 500px);
    overflow-y: auto;
  }

  &--profile {
    height: 24px;
    background: #589fa5;
    margin: 0px;
  }

  // main segment in dashboardcardcomponent file
  &--dashboard--height100vh {
    height: calc(100vh - 150px);
  }

  &__homepage--card {
    border-radius: 8px;
    cursor: pointer;
    padding-right: 0;
    padding-bottom: 0;

    &:hover {
      box-shadow: $color-box-shadow;
    }
  }
}

//Create user error
div.ui.basic.segment.createUser--error {
  margin-top: 50px;
}

div.ui.circular.segment.image--profile {
  border: 7px solid #fff;
  box-shadow: none;
  margin: 0 auto;
  top: -10px;
  display: inline-block;
  width: 127px;
  height: 127px;
}

div.ui.segment {
  // relation card of device and asset sidebar
  &.segment--relation {
    padding: 1em;
    border: 1px solid $color-secondary-active;
    box-sizing: border-box;
    border-radius: 0.5em;
    color: $color-secondary-active;
    // cursor: pointer;
    margin: 0 0.5em 1em 0.5em;

    div.ui.attached.label {
      top: -1px;
      left: 1em;
      border-radius: 0;
      font-size: $font-caption1;
      padding: 0.5em 1em;
      font-weight: $font-weight-initial;
      background-color: $color-secondary-active;
      color: $color-font-white;
    }

    &:hover {
      background-color: $color-primary;

      span,div.ui.header,.text-type {
        color: $color-font-white;
      }
      .ui.dropdown.dropdown__menu--etc {
        color: #efefef;
      }
    }

    div.footer-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .text-type {
        text-align: right;
      }
    }
  }
}

// card component of directory and dashboard
.ui.segment {
  &--dashboard {
    height: 185px;
    max-height: 185px;
    padding: 30px;
    border-radius: 8px;

    p.text--updateDate {
      margin-top: 30px;
      color: $color-font-empty;
    }

    &:hover {
      border-color: #00c192;
      cursor: pointer;

      div.ui.header {
        color: #00c192;
      }
      div.ui.dropdown {
        color: #00c192;
      }
      .svg-icon {
        &__addDashboard {
          #icon-add-plus {
            fill: #00c192;
          }
        }
      }
    }
  }

  &--dashboard--name {
    padding: 30px;
    border-radius: 8px;

    p.text--updateDate {
      margin-top: 30px;
      color: $color-font-empty;
    }

    &:hover {
      border-color: #00c192;
      cursor: pointer;

      div.ui.header {
        color: #00c192;
      }
      div.ui.dropdown {
        color: #00c192;
      }
      .svg-icon {
        &__addDashboard {
          #icon-add-plus {
            fill: #00c192;
          }
        }

        &__addDashboard-WithoutTop {
          #icon-add-plus {
            fill: #00c192;
          }
        }
      }
    }
  }
}

//segment fixed button
div.ui.basic.segment.sement--padding-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

//widget template
div.ui.segment.temp__segment {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 228px;
  cursor: pointer;
  margin-right: 0.5em;
  display: inline-grid;

  &:hover {
    border-color: #000000;
  }

  &--active {
    background-color: #e0e0e0;
    border: 1px solid #000000;
    border-radius: 8px;
    width: 228px;
    cursor: pointer;
    margin-right: 0.5em;
    display: inline-grid;
  }
}

// widget of segment
div.ui.segment.widget-template {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  min-width: 228px;
  height: 181px;
  cursor: pointer;
  margin-right: 15px;
  display: inline-block;

  &:hover {
    border-color: #000;
  }

  img.ui.image {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

//   .ui.segment.widget-template:hover .ui.header {
//     color: #00c192;
//   }
//   .ui.segment.widget-template:hover .svg-icon__dashboardTemplate {
//     fill: #999999;
//   }

// .ui.segment.widget-template:active {
//   background: #ffffff;
//   border-color: #000000;
// }
//   .ui.segment.widget-template:active .ui.header {
//     color: #000000;
//   }
//   .ui.segment.widget-template:active .svg-icon__dashboardTemplate {
//     fill: #999999;
//   }

// .ui.segment.widget-template:focus {
//   background: #ffffff;
//   border-color: #000000;
// }
//   .ui.segment.widget-template:focus .ui.header {
//     color: #000000;
//   }
//   .ui.segment.widget-template:focus .svg-icon__dashboardTemplate {
//     fill: #999999;
//   }

div.ui.segment.segment-telemetryKey {
  border: 1px solid $color-font-gray;
  border-radius: 0.3em;
  padding: 0.67857em 1em;
  color: $color-font-black !important;
  margin: 1rem 0rem 0rem !important;
}

div.ui.segment.segment-createTelemetry {
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
}

div.ui.segment.segment-deviceType {
  // height: 40em;
  height: calc(100vh - 170px);
  box-shadow: $segment-box-shadow !important;
  border-radius: 8px 8px 0 0;
  margin-top: 0px !important;
  padding: 0px !important;
}

div.ui.segment.segment-content-mobile {
  border: none !important;
  background: transparent;
}

div.ui.segment.cover-mobile {
  border: none !important;
  box-shadow: none !important;
}
