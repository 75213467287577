.very.wide.form__sidebar {
    width: 655px !important;
}

.very.wide.form__sidebar.device-sidebar {
    width: 1250px !important;
}

.ui.form .inline.fields .field.role-card {
    padding: 0px !important;
    .ui.card>.content {
        padding: 0.6rem 1rem !important;
    }
    .event .content {
        padding-left: 10px !important;
        align-self: center;
    }
    .event .button {
        &:hover {
            color: white;
            background-color: $btn-error-hover;
        }
    }
}