.ui.container {
  @media only screen and (max-width: 767px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.container__desc {
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 24px;
  color: $color-font-gray;
}

.container__desc--space-bot {
  margin-bottom: 32px;
}
