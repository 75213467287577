// body {
//     background-image: url(/images/background.svg);
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     overflow: hidden;
// }

*:focus {
    outline: none !important;
}