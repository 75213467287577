img.ui.centered.image {
  &.img--addMarginTop {
    margin-top: 40px;
  }
}

div.ui.image {
  &--profile {
    padding-top: 0.75em !important;
    z-index: 1;
  }
}

img.ui.centered.image.img--addNewUser {
  top: 20px;
}

img.ui.image {
  &.padding0 {
    padding-left: 0;
    padding-right: 0;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &.center--vertical {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  &.img--homepage {
    display: inline;
    border-radius: 10px;
  }
}

img.ui.inline.image.img {
  &--padding {
    padding: 0 0.8em;
  }
}

img.ui.image.img--addWidget {
  width: 32px;
  height: 32px;
  top: 15px;
}

.menu .item {
  padding-top: 5px;
  // margin-right: 20px;
  .logo {
    width: 150px;
  }
}