.ui.card > .content,
.ui.cards > .card > .content > .card-role-permit {
  font-style: normal;
  font-weight: normal;

  &__header {
    margin-top: 0 !important;
    font-size: 1.0714em !important;
    line-height: 1.6em !important;
    overflow-wrap: break-word;
  }

  &__desc {
    font-size: 0.8571em !important;
    line-height: 1.333em !important;
    color: $color-font-gray !important;
  }
}

.ui.card {
  &__acc-role {
    width: auto;
  }
}

.ui.cards a.card,
.ui.link.card,
.ui.link.cards .card,
a.ui.card {
  &__role {
    .card-role-permit__header>span {
      padding-top: 2px;
    }
    &:hover {
      transform: none;
      box-shadow: 0 0 0 2px $color-primary;

      .card-role-permit__header {
        color: $color-font-green;
      }
    }
  }
}

.ui.feed > {
  .event {
    &__sidebar {
      display: block;

      .extra {
        padding-left: 2.7143em !important;
      }
    }
  }
}

div.ui.grid {
  &.grid__card--widget {
    height: 100%;

    // header of dashboard widget
    div.row {
      &.row__header--widget {
        padding: 0.5em 0 !important;

        h1 {
          margin-top: 0.5em;
        }
      }
      &.row--detail {
        padding: 0;
        display: flex;
        justify-content: center;
      }
    }
  }
}
