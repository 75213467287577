div.ui.checkbox {
  &.checkbox--border-red {
    min-height: 31px;
    min-width: 31px;
    border-radius: 0.4em;
    border: 1px solid $check-box-border-red;

    label::before {
      background: none;
      border: none;
      width: 25px;
      height: 25px;
    }
    label::after {
      color: $color-font-error !important;
      background: none;
      border: none;
      padding: 0.2em;
      font-size: $font-title2;
      width: 25px;
      height: 25px;
    }

    &:hover {
      background-color: $check-box-background-hover;

      label::after {
        background-color: $check-box-background-hover;
        color: #fff !important;
      }
    }
  }

  &.checkbox--border-primary {
    min-height: 30px;
    min-width: 30px;
    border-radius: 0.4em;
    border: 1px solid $check-box-border-primary;

    label::before {
      background: none;
      border: none;
      width: 25px;
      height: 25px;
    }
    label::after {
      color: $color-font-green !important;
      background: none;
      border: none;
      padding: 0.2em;
      font-size: $font-title2;
      width: 25px;
      height: 25px;
    }

    &:hover {
      background-color: $check-box-background-primary-hover;

      label::after {
        background-color: $check-box-background-primary-hover;
        color: #fff !important;
      }
    }
  }
}

// Radio checkbox login page
div.ui.radio.checkbox {
  &.radio--square {
    label {
      color: $label-color;
      transition: 0.1s ease;
      font-size: 16px;
      display: initial;
    }

    label:before,
    label:after {
      width: 20px;
      height: 20px;
      border-radius: 0px;
    }

    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      background-color: $checkBox-login-active;
    }

    input:checked ~ label:after {
      background-color: $checkBox-login-active;
    }

    input:focus:checked ~ label:after {
      background-color: $checkBox-login-active;
    }

    input:checked + label {
      color: $checkBox-login-active;
    }
  }
}


// Radio checkbox form in modal
.ui.form .inline.field div.ui.radio.checkbox {
  &.radio--square {
    label {
      color: $label-color;
      transition: 0.1s ease;
      font-size: .92857143em;
      display: initial;
      padding-top: 1.5px;
    }

    label:before,
    label:after {
      width: 20px;
      height: 20px;
      border-radius: 0px;
    }

    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      background-color: $checkBox-login-active;
    }

    input:checked ~ label:after {
      background-color: $checkBox-login-active;
    }

    input:focus:checked ~ label:after {
      background-color: $checkBox-login-active;
    }

    input:checked + label {
      color: $checkBox-login-active;
    }
  }
}

td .ui.checkbox {
  vertical-align: middle;
  line-height: 20px;
}