div.ui.grid {
  &.grid--height100per {
    height: 100%;
  }

  div.row {
    &.remove-padding {
      padding-top: 0;
    }

    &.remove-paddingBottom {
      padding-bottom: 0;
    }
  }

  div.column {
    &.alignEnd {
      align-self: flex-end;
    }
  }
}

div.ui.grid.login-grid {
  padding-top: 5%;

  div.six.wide.column.col-login-form {
    padding-left: 30px;
    padding-right: 30px;
  }
}

div.ui.grid.grid-form {
  div.fourteen.wide.column {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.grid__logo {
  margin-left: 88px;
  margin-top: 40px;
}

.grid__img {
  margin-left: 80px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.grid__input {
  padding-top: 56px;
  margin-right: 80px;
  padding-bottom: 56px;
}

.grid__btn--space-t-b {
  margin-top: 48px;
  margin-bottom: 56px;
}

.grid__form--space {
  margin-top: 24px !important;
}

.grid__form-input-col--top {
  margin-top: 32px;
}

// directory
div.ui.grid.grid__col12--directory {
  height: calc(100vh - 260px);
  background-color: $grid-background;
  box-shadow: $grid-box-shadow !important;
  border-radius: 8px;

  // div.row {
  //   padding-top: 0px;
  //   padding-bottom: 0px;
  // }

  div.sixteen.wide.column {
    padding: 0px;
    height: 100%;
  }
}

.ui.grid {
  &__detail-page {
    margin-top: -2.2858rem;
  }

  &__in-sidebar {
    margin: 0 !important;
    >.row {
      padding: 0px !important;
    }
  }
}

.ui.grid > .row {
  &__segment {
    padding-bottom: 0 !important;
  }

  &--header {
    padding-top: 2.8572rem !important;
    padding-bottom: 0;
    &__activity {
      padding-top: 1.8572rem !important;
      padding-bottom: 0;
    }
  }

  &--content {
    padding-top: 1.1429em;
  }
}

.ui.grid .column {
  &--header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    // text-align: right !important;
  }

  &--body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

div.ui.grid > .grid-tab {
  padding-bottom: 1.7143em;

  &__user {
    padding-top: 0;
    padding-bottom: 1.1429em;

    &--middle {
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
}

// dashboard template
div.ui.grid {
  // dashboard template
  &.grid__dashboard {
    margin: 0;
    height: calc(100vh - 230px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  // sub grid grid__sub
  &.grid__sub {
    &--index0 {
      margin-top: -0.5em;
    }
  }

  // dashboard template cover
  &.grid__dashboard--cover {
    height: 150px;
    margin: -0.5rem;
  }

  // child grid
  &.gridchild__dashboard--cover {
    height: 50%;
    margin: 0 -0.3rem;
  }

  // height of main row
  div.row {
    &.grid__row {
      &--height4 {
        height: calc(100vh / 2);
        padding: 0.5rem 0;
      }
      &--height3 {
        height: calc(100vh / 3);
        padding: 0.5rem 0;
      }
      &--height2 {
        height: calc(100vh / 4);
        padding: 0.5rem 0;
      }
      &--height1 {
        height: calc(100vh / 8);
        padding: 0.5rem 0;
      }
    }

    // height of main child row
    &.gridchild__row {
      &--height4 {
        height: 66.66%;
        padding: 0.3rem 0;

        div.column {
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
      }
      &--height3 {
        height: 50%;
        padding: 0.3rem 0;

        div.column {
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
      }
      &--height2 {
        height: 33.33%;
        padding: 0.3rem 0;

        div.column {
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
      }
      &--height1 {
        height: 16.66%;
        padding: 0.3rem 0;

        &.rowIndex {
          &--0 {
            height: 100%;
            padding: 0 0 0.3rem 0;
          }
          &--1 {
            height: 100%;
            padding: 0.3rem 0 0 0;
          }
        }

        div.column {
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
      }
    }
  }

  div.column {
    &.grid__col {
      height: 100%;
      padding: 0 0.5rem;

      &--index0 {
        height: 100%;
        padding: 0 0.5rem 0 1rem;
      }
      &--index1 {
        height: 100%;
        padding: 0 1rem 0 0.5rem;
      }
    }

    &.grid__description {
      padding-top: 0.8rem;
    }

    &.grid__directory-content {
      padding-bottom: 0px;
      .column{
        height: 100%;
      }
    }

    // &.col--custom {
    //   height: 100%;
    //   padding: 0 .3rem;
    // }
  }

  div.ui.segment {
    &.segment__widget {
      background-color: #e0e0e0;
      border-radius: 8px;
      border: none;
      height: 100%;

      &:hover {
        border: 1px solid #000;
      }
    }
    &.segment__widget--detail {
      background-color: #fff;
      border-radius: 8px;
      height: 100%;
    }
    &.segment__widget--preview {
      background-color: #fff;
      border-radius: 8px;
      height: 80%;
    }
  }

  div.segment__widget--child {
    background-color: #fff;
    border-radius: 2px;
    border: none;
    height: 100%;
  }

  // line chart
  div.div__overflow {
    overflow: hidden;
    height: 80%;

    &:hover {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  div.div__center {
    text-align: center;
  }
}

// dashboard of directory card component
div.ui.grid {
  &.grid__sengment--directory {
    height: calc(100vh - 250px);
    overflow-y: auto;
  }
}

div.ui.grid.grid--addData {
  div.row.row--addData {
    padding-bottom: 0px;
  }

  div.row.row--addData-addPaddingTop {
    padding-top: 5px;
    align-items: center;
  }
}

div.ui.grid.grid--addTop {
  margin-top: 50px;
}

.ui.grid > .stretched.row > .column > * {
  flex-grow: 0;
}

div.ui.grid {
  div.row.back--to-role {
    // margin-top: -1.25em;
    margin-bottom: 1.25em;
    border-radius: 0.3125em;
    padding: 0.625em;
  }
  i.icon.arrow-left {
    color: #ffffff;
    font-size: $font-body;
  }
}

.btn-header-menu {
  text-align: right;
  &.btn-column {
    width: 50px !important;
    margin-left: 0px !important;
    .button__icon{
      padding-left: 6.5px !important;
    }
  }
}