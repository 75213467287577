i.icon {
  &.icon--font16 {
    font-size: 16px;
  }

  &.icon--font24 {
    font-size: 24px;
  }

  &.icon--font32 {
    font-size: 32px;
  }

  &.color--gray {
    color: $icon-secondary-active;
  }

  &.marginLeft--25 {
    margin-left: 25px !important;
  }

  &.color--gray--inactive {
    color: $icon-secondary-inactive;
  }

  &.color--lightgray--active {
    color: $icon-secondary-active;
  }

  &.icon--hover:hover {
    color: $icon-secondary-hover;
  }

  &.icon--cursor {
    cursor: pointer;
  }

  &.icon__svg--role {
    background-color: $icon-color-red;
  }

  &.icon--border {
    border: none;
    box-shadow: none;
    border-radius: 0.4em;
    background-color: $icon-background !important;
    color: $color-font-white;
  }

  &__roles {
    margin-bottom: 0.5714em;
    margin-right: 0.5714em;
    margin-left: 0.5714em;
  }
}

i.icons {
  &__sidebar {
    float: left;
  }
}

span {
  &.icon--mg--left {
    margin-left: 10px;
  }
}
