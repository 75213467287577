%btn--normal {
  font-size: $font-body;
  color: $color-font-white;
  border-radius: 0.3em;
  padding: 1em 1.5em 1em;
}

%btn--curve {
  color: $color-inactive-gray;
  border: 1px solid $color-inactive-gray;
  background-color: $color-font-white;
  border-radius: 2rem;
  font-weight: 400;
}

button.ui.button {
  // login,change pass btn
  &.btn--primary {
    @extend %btn--normal;
    background-color: $btn-primary-active;

    &.btn--width144px {
      width: 144px;
    }

    &.btn--width100p {
      width: 100%;
    }

    &.btn--width305px {
      width: 19.0625em;
    }

    &:hover {
      background-color: $btn-primary-hover;
      box-shadow: $color-box-shadow;
    }
  }

  &.btn--secondary {
    @extend %btn--normal;
    background-color: $btn-secondary-active;

    &.btn--width144px {
      width: 144px;
    }

    &.btn--width100p {
      width: 100%;
    }

    &.btn--margin {
      margin: 0 3em 3em 3em;
    }

    &:hover {
      background-color: $btn-secondary-hover;
      box-shadow: $color-box-shadow;
    }
  }

  // profile btn
  &.btn--curve {
    @extend %btn--curve;
    width: 100%;

    &.mg--bottom {
      margin-bottom: 1em;
    }

    p.font--caption1 {
      font-size: $font-caption1;
      font-weight: 400;
      margin: 0;
    }

    &:hover,
    &:focus {
      color: $color-primary;
      border: 1px solid $color-primary;
    }
  }

  // button of sidebar
  &__cancel {
    background-color: $btn-secondary-active;
    color: $color-font-white !important;
    font-size: 1.1429em !important;
  }

  &__submit {
    background-color: $btn-primary-active;
    color: $color-font-white !important;
    font-size: 1.1429em !important;

    &:disabled {
      background-color: $btn-primary-active;
    }
  }

  // button image custom
  &__image {
    margin-left: 0.5714em !important;
    padding: 0 !important;
    box-shadow: none !important;

    &--sumbit-role {
      margin-left: 0 !important;
      margin-right: 0 !important;
      text-align: right;
    }
  }

  &__icon {
    width: 32px !important;
    height: 32px !important;
    border-radius: 8px !important;
    padding: 7px !important;
    box-shadow: none !important;
    margin: 0px !important;
    &.mg-left {
      margin-left: 0.5714em !important;
    }

    &.mg-right {
      margin-right: 0.5714em !important;
    }

    &.pd-left {
      padding-left: 6.75px !important;
    }

    &.pd-left-2 {
      padding-left: 5.75px !important;
    }

    &.pd-top {
      padding-top: 5.5px !important;
    }

    &.btn_icon--primary {
      background: $btn-primary-active !important;
      border: 1px solid $btn-primary-active !important;

      .icon {
        color: #FFFFFF !important;
      }
      &:hover {
        background: $btn-primary-hover !important;
        border: 1px solid $btn-primary-hover !important;
        .icon::before {
          color: #FFFFFF !important;
        }
      }
    }
    &.btn_icon--primary-outline {
      background: #FFFFFF !important;
      border: 1px solid $btn-primary-active !important;

      .icon {
        color: $btn-primary-active !important;
      }
      &:hover {
        background: $btn-primary-active !important;
        border: 1px solid $btn-primary-active !important;
        .icon::before {
          color: #FFFFFF !important;
        }
      }
    }
    &.btn_icon--primary-inactive {
      background: #FFFFFF !important;
      border: 1px solid $btn-primary-inactive !important;

      .icon {
        color: $btn-primary-inactive !important;
      }
      &:hover {
        background: $btn-primary-active !important;
        border: 1px solid $btn-primary-active !important;
        .icon::before {
          color: #FFFFFF !important;
        }
      }
    }
    &.btn_icon--third {
      background: $btn-third-active !important;
      border: 1px solid $btn-third-active !important;

      .icon {
        color: #FFFFFF !important;
      }
      &:hover {
        background: $btn-third-hover !important;
        border: 1px solid $btn-third-hover !important;
        .icon::before {
          color: #FFFFFF !important;
        }
      }
    }
    &.btn_icon--info {
      background: $btn-info-active !important;
      border: 1px solid $btn-info-active !important;

      .icon {
        color: #FFFFFF !important;
      }
      &:hover {
        background: $btn-info-hover !important;
        border: 1px solid $btn-info-hover !important;
        .icon::before {
          color: #FFFFFF !important;
        }
      }
    }
    &.btn_icon--danger {
      background: $btn-error-active !important;
      border: 1px solid $btn-error-active !important;

      .icon {
        color: #FFFFFF !important;
      }
      &:hover {
        background: $btn-error-hover !important;
        border: 1px solid $btn-error-hover !important;
        .icon::before {
          color: #FFFFFF !important;
        }
      }
    }
    &.btn_icon--danger-outline {
      background: #FFFFFF !important;
      border: 1px solid $btn-error-active !important;

      .icon {
        color: $btn-error-active !important;
      }
      &:hover {
        background: $btn-error-active !important;
        border: 1px solid $btn-error-active !important;
        .icon::before {
          color: #FFFFFF !important;
        }
      }
    }
  }




  &__change-role {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;

    &:focus {
      background-color: transparent;
    }
  }

  // btn device attribute
  &.btn--add {
    background-color: $btn-primary-active;
    color: $color-font-white;
    margin-top: 10px;
  }
}

// button icon with border
button.ui.icon.button {
  &.btn--icon--row {
    border-radius: 0.6em;
    margin: 0 auto;
    display: block;

    &.col--token {
      padding: 0.4em 0.4em 0.2em 0.4em;
      background-color: $btn-secondary-active;
      color: $color-font-white;

      &:hover {
        background-color: $btn-primary-hover;
      }
    }
    &.col--export {
      padding: 0.4em 0.45em 0.2em;
      background-color: $icon-background;
      color: $color-font-white;

      &:hover {
        background-color: $btn-primary-hover;
      }
    }
    &.col--error {
      padding: 0.4em 0.45em 0.2em;
      background-color: $color-error;
      color: $color-font-white;

      &:hover {
        background-color: $btn-error-hover;
      }
    }
    &.col--error-left {
      padding: 0.4em 0.45em 0.2em;
      background-color: $color-error;
      color: $color-font-white;
      margin-left: 0px;

      &:hover {
        background-color: $btn-error-hover;
      }
    }
  }
  &.btn--icon--row--top {
    margin: 0 0 0 10px;
    display: inline-block;
    &.col--export {
      padding: 0.4em 0.5em 0.2em;
    }
  }

  &.btn--icon--border {
    padding: 0.78571429em 0.6em;
    border-radius: 0.5em;

    &.main {
      margin-left: 0.7em;
      background: none;
      color: $btn-secondary-active;
      border: 1px solid $btn-secondary-active;

      &:hover {
        background-color: $btn-primary-hover;
        border: 1px solid $btn-secondary-hover;
        color: $color-font-white;
      }
    }

    &.border--red {
      background: none;
      color: $color-font-error;
      border: 1px solid $btn-error-active;
      margin-left: 0.7em;

      &:hover {
        color: $color-font-white;
        background-color: $btn-error-active;
      }
    }

    i.icon {
      font-size: $font-body;
    }
  }
}

button.ui.icon.button.btn--icon-border:active {
  background-color: $btn-primary-active;
  color: $color-background !important;
}

// button secondary color
button.ui.button.btn--inactive-gray {
  background: $btn-secondary-inactive;
  color: $color-font-white;
  font-size: $font-body;
  font-weight: normal;
  margin: 5px;
  width: 132px;
  height: 48px;
  border-radius: 8px;

  &:hover {
    box-shadow: $color-box-shadow;
  }
}

// button green active color
button.ui.button.btn--active-green {
  background: $btn-primary-active;
  color: $color-font-white;
  font-size: $font-body;
  font-weight: normal;
  margin: 5px;
  width: 132px;
  height: 48px;
  border-radius: 8px;

  &:hover {
    box-shadow: $color-box-shadow;
  }
}

// button filter in sidebar
// button.ui.button.btn--filter {
//   width: 128px;
//   height: 32px;
//   padding: 0px;
//   border-radius: 8px;
//   margin-left: 5px;
//   margin-bottom: 10px;
//   border: 1px solid $color-secondary;
//   color: $color-font-gray;
//   box-shadow: none;
//   background-color: $color-background;
//   font-size: 13px;
//   font-weight: normal;

//   &:hover,
//   :active {
//     border: 1px solid $color-primary;
//     color: $color-primary;
//   }
// }

button.ui.button.btn--addData {
  width: 149px;
  height: 48px;
  background: #589fa5;
  border-radius: 8px;
  padding: 5px;
}

button.ui.button {
  &.btn-addTelemetry {
    background: $color-background;
    border: 1px solid $color-font-green;
    color: $color-font-green;
  }

  &.btn-createTelemetry {
    background: $btn-primary-active;
    color: $color-font-white;
  }

  &.btn-cancelTelemetry {
    background: $btn-secondary-active;
    color: $color-font-white;
  }

  &.btn-deleteTelemetry {
    background: $btn-error-active;
    color: $color-font-white;
  }
}
