
div.column {
  // btn in col (create and edit modal)
  &.col8__btn--left {
    padding-right: .3rem;
  }
  &.col8__btn--right {
    padding-left: .3rem;
  }

  // col for scrolling
  &.grid__scrolling--vertical {
    height: 100%;
    overflow: auto;
    padding: 0 1rem 1rem 1rem !important;
    margin: 1rem 0;
  }
}