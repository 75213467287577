$input--padding: 1em 1em 0.67857143em;

div.div__input {
  height: 50px;
  margin-bottom: 30px;
  border-bottom: 1.5px solid $color-inactive-gray;

  &:focus-within {
    border-bottom: 1.5px solid $color-primary;
  }
}

div.ui.input {
  &.input--withicon {
    color: $color-inactive-gray;

    input {
      border: none;
      font-size: $font-body;
      margin-left: 40px;
    }

    i.icon {
      border-right: 1.5px solid $color-inactive-gray;
    }

    &:focus-within {
      i.icon {
        color: $color-primary;
        border-right: 1.5px solid $color-primary;
      }
    }
  }
}

// animation field

div.normalfield__group {
  position: relative;
  padding: 1em 0 0;
  width: 100%;

  &.pd--bottom {
    padding-bottom: 1em;
  }

  &.pd--no-top {
    padding-top: 0;
  }
}

label.normalfield__label {
  font-size: 12px !important;
  font-weight: 400;
  color: #c4c4c4;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 1rem;
  display: block;
  padding: 0.3em 0.3em;
  transition: 0.2s;
}

input,
textarea,
input.search {
  &.normalfield__field {
    width: 100%;
    border: 1px solid $color-inactive-gray;
    border-radius: 0.3em;
    padding: $input--padding !important;
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    background: transparent;
    transition: border-color 0.2s;

    &:disabled {
      color: rgba(143,143,143, 1) !important;
    }

    &::placeholder {
      color: transparent;
      opacity: 0;
    }

    &.align--center {
      text-align: center;
    }

    &:placeholder-shown ~ label.normalfield__label {
      font-size: 16px !important;
      cursor: text;
      top: 20px;
    }

    &:focus {
      ~ label.normalfield__label {
        position: absolute;
        top: 0;
        display: block;
        background-color: #fff;
        color: $color-primary;
        font-size: 12px !important;
        font-weight: 600;
        transition: 0.2s;
        font-weight: normal;
      }

      border: 1px solid $color-primary !important;
    }
  }
}

div.ui.input {
  &.copyfield {
    font-size: $font-body;

    input {
      color: $color-font-gray;
    }
  }
}

textarea {
  &.normalfield__field {
    height: 140px;
  }
}

// Search
div.ui.input.input__search--withoutBorder {
  height: 30px;
  width: 100%;

  input {
    border: none;
    font-size: $font-body !important;
    margin-left: 2em;
    background: none;
  }

  i.icon {
    color: $color-font-gray;
    border-right: 1.5px solid $color-font-gray;
    font-size: $font-icon;
  }

  &:focus-within {
    i.icon {
      color: $color-primary;
      border-right: 1.5px solid $color-primary !important;
    }
  }

  div.ui.search.search-withoutBorder > .results {
    width: 100%;
  }

  input.text-align--center {
    text-align: center;
  }

  input.floating-label-field.text-align--center.border--disable {
    border: 1px solid #c4c4c4;
    border-radius: 8px;
  }
}

//input color
input.input--color {
  background: #ffffff;
  color: #ffffff;
  border: none;
  width: 53px;
  height: 25px;
  position: absolute;
  top: 23px;
  left: 20px;
}

// input tag
div.ui.segment.input--tag__tags {
  box-shadow: none;
  padding: 0.4em;

  div.ui.label {
    margin: 2px;
  }

  div.ui.input.input--attributesReport {
    margin-left: 5px;
    padding-top: 2px;
  }
}

// update label
div.ui.input.input-updateLabel {
  border-bottom: 1px solid #c4c4c4;
  margin: 0 1em;
  width: 50%;
}

div.ui.input.input-telemetryKey {
  input {
    border: none;
    font-family: "Cambay", sans-serif;
    padding-left: 5px !important;
  }
}
