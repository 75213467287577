div.div__accordion {
  padding-right: 1em;
  height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: hidden;
}

// only scroll
div.div__accordion::-webkit-scrollbar-track {
  background: none !important;
}

div.accordion.ui {
  div {
    font-size: $font-body;
    font-weight: 400;
    border-radius: 0.5em;

    &.accordion__menu--inactive {
      background-color: $accordion-inactive;
      padding: 0.67857143em 1em;
      margin-bottom: 0.5em;
      div.title {
        color: $color-font-white !important;
      }

      &:hover {
        background: $accordion-hover;
        box-shadow: $color-box-shadow;
      }
    }

    &.accordion__menu--active {
      border: 1px solid $accordion-active;
      background-color: transparent;
      margin-bottom: 0.5em;
      padding: 0.67857143em 1em 1em;

      div.active.title {
        &:hover {
          color: $color-font-green;
        }
      }
    }
  }
}
