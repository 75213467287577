// color
$color-primary: #00A8A9;
$color-primary-dark: #589fa5;
$color-secondary: #c4c4c4;
$color-third: #5FC1C9;
$color-error: #D35344;
$color-background: #ffffff;

// active
$color-active-dark-green: #589fa5;
$color-secondary-active-darker: #999999;
$color-secondary-active: #a3a3a3;

// inactive
$color-inactive-green-light: #5fc0a8;
$color-inactive-gray: #c4c4c4;
$color-inactive-darkgray: #999999;
$color-secondary-inactive: #e1e1e1;

// text color
$color-font-primary: $color-primary;
$color-font-error: $color-error;
$color-font-gray: $color-secondary;
$color-font-green: $color-primary;
$color-font-green-dark: $color-primary-dark;
$color-font-white: #ffffff;
$color-font-black: #000000;
$color-font-empty: #999999;
$color-placeholder: #a3a3a3;
$color-font-warning: #dfab00;

// box shadow
$color-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

// scrollbar
$scrollbar-active: #333;
$scrollbar-background: #c4c4c4;
$scrollbar-background-white: #ffffff;
$scrollbar-hover: #777;

// ------------------------ button ------------------------
// button primary
$btn-primary-active: #00A8A9;
$btn-primary-inactive: #c4c4c4;
$btn-primary-hover: #00bfbf;

// button secondary
$btn-secondary-active: #a3a3a3;
$btn-secondary-inactive: #e1e1e1;
$btn-secondary-hover: #aeaeae;

// button third
$btn-third-active: #5FC1C9;
$btn-third-inactive: #c4c4c4;
$btn-third-hover: #69d6e0;


// button info
$btn-info-active: #0074B0;
$btn-info-inactive: #0082c4;
$btn-info-hover: #008ed4;

// button danger
$btn-error-active: #D35344;
$btn-error-inactive: #fab9af;
$btn-error-hover: #ee5d4d;

// directory page
// accordion
$accordion-active: #00A8A9;
$accordion-inactive: #999999;
$accordion-hover: #00A8A9;

// check box for delete
$check-box-border-red: #D35344;
$check-box-background-hover: #D35344;

// check box for export
$check-box-border-primary: #00A8A9;
$check-box-background-primary-hover: #00d8d8;

// dropdown
$dropdown-background: #ffffff;
$dropdown-border: #c4c4c4;
$dropdown-active: #00A8A9;

//check box on login page
$checkBox-login-active: #00A8A9;

//  grid
$grid-background: #ffffff;
$grid-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
  4px 0px 4px rgba(0, 0, 0, 0.25);

//  icon
$icon-secondary-inactive: #e0dbdb;
$icon-secondary-active: #a3a3a3;
$icon-secondary-hover: #00A8A9;
$icon-color-red: red;
$icon-background: #c4c4c4;

//  label
// label tag
$label-tag-background: rgba(88, 159, 165, 0.2);
$label-tag-text: #00A8A9;

$label-color: #c4c4c4;
$label-color-hover: #00A8A9;
$label-border-color: #a3a3a3;

// modal
$modal-success-color: #dce7e4;
$modal-failed-color: #d9c3c3;

// segment
$segment-background: #ffffff;
$segment-border: #00A8A9;
$segment-box-shadow: 0px 4px 4px rgba(0, 51, 38, 0.5),
  4px 0px 4px rgba(0, 51, 38, 0.5);

// add directory button
$segment-add-directory-color: #00A8A9;
$segment-add-directory-hover: #ffffff;

// table
$table-header-background: #00A8A9;
$table-header-color: #ffffff;
$table-border-bottom: rgba(0, 0, 0, 0.1);
$table-body-color: #000000;
$table-row-hover: #00A8A9;
