div.ui.pagination.menu {
  &.pagination--noneborder {
    border: none;
    box-shadow: none;

    a.item {
      padding: 1em;
      min-width: 3em;
    }

    a.active.item {
      padding: 1em;
      background-color: transparent;
    }
  }

  &.pagination--table {
    border: none;
    box-shadow: none;

    a.item {
      position: unset;
    }

    a.active.item {
      position: unset;
      background-color: transparent;
    }

    a.item::before {
      background: none;
    }
  }
}
