.svg-icon {
  // fill: #c4c4c4;
  vertical-align: middle;
  stroke: #ffffff;
  cursor: pointer;

  &__back {
    fill: $color-inactive-gray;
    stroke: none;

    &:hover {
      fill: $color-primary;
      stroke: none;
    }
  }

  &__add {
    stroke: $color-inactive-gray;
    fill: $color-background;

    #icon-add-plus {
      fill: $color-inactive-gray;
    }

    &:hover {
      fill: $color-primary;
      stroke: none;

      #icon-add-plus {
        fill: $color-background;
      }
    }
  }

  &__addDashboard {
    stroke: $color-background;
    fill: $color-background;

    display: block;
    margin: 0 auto;
    margin-top: 25px;

    #icon-add-plus {
      fill: $color-inactive-gray;
    }
  }

  &__addDashboard-WithoutTop {
    stroke: $color-background;
    fill: $color-background;

    display: block;
    margin: 0 auto;

    #icon-add-plus {
      fill: $color-inactive-gray;
    }
  }

  &__bin {
    stroke: #ffffff;
    fill: #c4c4c4;

    &:hover {
      fill: $color-error;
      stroke: #ffffff;
    }
  }

  &__bin--noneborder {
    stroke-width: 0.5;
    stroke: none;
    fill: none;

    &:hover {
      fill: rgba(0, 0, 0, 0.1);
    }
  }

  &__edit {
    stroke: #a3a3a3;
    fill: #fff;
    margin-left: 10px;

    &:hover {
      stroke: #ffffff;
      fill: $color-primary;
      &__remover-user {
        stroke: #ffffff;
        fill: #c4c4c4;

        #remove-user {
          fill: $color-background;
        }

        &:hover {
          fill: $color-error;
          stroke: #ffffff;
        }
      }
    }
  }

  &__edit--noneborder {
    stroke-width: 0.5;
    stroke: none;
    fill: none;
    margin-left: 5px;

    &:hover {
      fill: rgba(0, 0, 0, 0.1);
    }
  }

  &__check-unassign {
    stroke: $color-error;
    fill: $color-background;

    #icon-check {
      fill: $color-background;
    }

    &--checked {
      #icon-check {
        fill: $color-error;
      }

      &:hover {
        fill: red;

        // #icon-check {
        //   fill: $color-background;
        // }
      }
    }
  }

  &__check-unassign-primary {
    stroke: $color-primary;
    fill: $color-background;

    #icon-check {
      fill: $color-background;
    }

    &--checked {
      #icon-check {
        fill: $color-primary;
      }

      &:hover {
        fill: $color-primary;

        // #icon-check {
        //   fill: $color-background;
        // }
      }
    }
  }

  &__check-assign {
    stroke: $color-inactive-gray;
    fill: $color-background;

    &--add {
      stroke: $color-inactive-gray;
      fill: $color-background;

      #icon-check {
        fill: $color-inactive-gray;
      }

      &:active {
        stroke: $color-background;
        fill: $color-primary;

        #icon-check {
          fill: $color-background !important;
        }
      }

      &:hover {
        stroke: $color-primary;

        #icon-check {
          fill: $color-primary;
        }
      }
    }

    #icon-check {
      fill: $color-background;
    }

    &--checked {
      stroke: $color-primary;

      #icon-check {
        fill: $color-primary;
      }
    }
  }

  &__check {
    stroke: #c4c4c4;
    fill: #ffffff;
    margin-left: 10px;

    #icon-check {
      fill: #c4c4c4;
    }

    &:hover {
      fill: $color-primary;
      stroke: none;

      #icon-check {
        fill: $color-background;
      }
    }
  }
  &__user {
    &--wider {
      margin-right: 0.5333em;
    }
  }

  &--white {
    stroke: $color-inactive-gray;
    fill: $color-background;

    #filter-img {
      fill: $color-inactive-gray;
    }

    &:hover {
      fill: $color-primary;
      stroke: none;

      #filter-img {
        fill: $color-background;
      }

      stroke: $color-inactive-gray;

      &:hover {
        fill: $color-primary;
        stroke: $color-background;
      }
    }

    &__user {
      fill: none;
      stroke: #ffffff;

      #icon-user {
        stroke: $color-background;
      }

      &--wider {
        margin-right: 0.5333em;
      }

      &--header-table {
        #circle-user {
          fill: $color-active-dark-green;
        }

        #icon-user {
          fill: $color-active-dark-green;
        }
      }
    }
  }

  &--userTable {
    fill: #ffffff;

    #icon-user {
      stroke: $color-active-dark-green;
    }
  }

  &__manageDashboard {
    stroke: #a3a3a3;
    fill: #ffffff;
    margin-left: 10px;

    &:hover {
      fill: $color-primary;
      stroke: #ffffff;
    }
  }

  &__addWidget {
    fill: none;
    stroke: none;

    #icon-add-plus {
      fill: #ffffff;
    }
  }

  &__dashboardTemplate {
    fill: #ffffff;
    stroke: none;

    &:hover {
      fill: #999999;
    }
  }

  &__save {
    stroke: #ffffff;
    fill: #c4c4c4;

    &:hover {
      fill: $color-primary;
      stroke: #ffffff;
    }
  }
}
