.jsoneditor-react-container>.jsoneditor{
    border: thin solid $color-primary !important;
    .jsoneditor-menu {
        background-color: $color-primary !important;
    }
}

.jsoneditor-react-container>.jsoneditor.jsoneditor-mode-preview{
    border: thin solid $color-inactive-darkgray !important;
    .jsoneditor-menu {
        background-color: $color-inactive-darkgray !important;
    }
}