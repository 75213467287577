$miniRaw: 0.78571429;
$tinyRaw: 0.85714286;
$smallRaw: 0.92857143;
$largeRaw: 1.14285714;
$bigRaw: 1.28571429;
$hugeRaw: 1.42857143;
$massiveRaw: 1.71428571;

.form-selection {
  font-size: 1.1429em !important;
  line-height: 1.375em !important;
  padding-right: 0.5em !important;

  &.field > .selection.dropdown {
    width: 100% !important;
  }
}

div.thirteen.wide.field.form-selection {
  div.ui.fluid.selection.dropdown {
    border-radius: 8px;
    color: #c4c4c4;
    text-align: center !important;
  }

  i.dropdown.icon {
    color: $color-inactive-gray;
    font-size: 24px;
    top: 5px;
    padding-right: 10px;
  }
}

.ui.form {
  &__sidebar {
    background-color: $color-background;
    overflow-x: hidden !important;
  }

  .fields {
    &--btn-center {
      justify-content: center;
    }

    &__sidebar {
      padding-left: 2.2857em;
      padding-right: 2.2857em;
      margin-bottom: 0.5714em !important;
    }
  }

  .field {
    &__role-list {
      padding-left: 0 !important;
      padding-right: 0 !important;

      &--full {
        padding-bottom: 0 !important;
        padding-top: 0 !important;
      }
    }

    &--no-right {
      padding-right: 0 !important;
    }

    &__sidebar {
      padding-left: 2.2857em;
      padding-right: 2.2857em;
      margin-bottom: 0.5714em !important;

      &--last {
        margin-bottom: 1.7143em !important;
      }
    }

    &--picture {
      margin-bottom: 1.7143em !important;
    }
  }

  /* Fix form inheritence (em instead of rem) */
  .ui.checkbox {
    font-size: 1em;
  }

  $checkboxSizes: (
    "mini": $miniRaw,
    "tiny": $tinyRaw,
    "small": $smallRaw,
    "large": $largeRaw,
    "big": $bigRaw,
    "huge": $hugeRaw,
    "massive": $massiveRaw,
  );

  @each $name, $value in $checkboxSizes {
    .ui.#{$name}.checkbox {
      font-size: $value + em;
    }

    @if $value>1 {
      .ui.#{$name}.form .checkbox,
      .ui.#{$name}.checkbox {
        &:not(.slider):not(.toggle):not(.radio) {
          & .box:after,
          .box:before,
          label:after,
          label:before {
            transform: scale($value);
            transform-origin: left;
          }
        }

        &.radio {
          & .box:before,
          label:before {
            transform: scale($value);
            transform-origin: left;
          }

          & .box:after,
          label:after {
            $circleScale: $value / 2;
            $circleLeft: ($value - $circleScale) / 2 + 0.05 + em;
            transform: scale($circleScale);
            transform-origin: left;
            left: $circleLeft;
          }
        }
      }
    }
  }

  /* just here for quick list display */
  .column > .ui.checkbox {
    display: block;
    padding-bottom: 1em;
  }
}
