div.ui.dropdown.dropdown__menu--etc {
  position: absolute;
  // top: 0.5em;
  right: 1em;

  // float: right;
  // top: 0;
  // margin-left: auto!important;

  div.menu.transition {
    width: 133px;
    margin-bottom: 1em;
    margin-left: auto !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;

    div.item {
      text-align: center;
      border-radius: 8px !important;
      font-size: $font-secondary !important;
      color: $color-font-gray !important;

      &:hover {
        color: $color-primary !important;
        background-color: $dropdown-background !important;
      }
    }
  }

  .menu {
    right: 0 !important;
    left: none !important;
  }
}

.btn-dropdown-menu {
  width: 32px !important;
  height: 32px !important;
  box-sizing: border-box;
  background: #FFFFFF !important;
  border: 1px solid #c4c4c4 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  align-content: center !important;
  text-align: center !important;
  position: relative !important;
  margin-left: 0.5714em !important;
  padding: 8px !important;
  .icon {
    position: absolute !important;
    left: 9px !important;
    color: #c4c4c4 !important;
  }
  &:hover {
    background: $color-primary !important;
    border: 1px solid $color-primary !important;
    .icon::before {
      color: #FFFFFF !important;
    }
  }
  div.menu.transition {
    width: 133px !important;
    margin-bottom: 1em !important;
    margin-left: auto !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 8px !important;

    div.item {
      text-align: center !important;
      border-radius: 8px !important;
      font-size: $font-secondary !important;
      color: $color-font-gray !important;
      margin-right: 0px !important;
      &:hover {
        color: $color-primary !important;
        background-color: $dropdown-background !important;
      }
    }
  }

  .menu {
    right: 0 !important;
    left: none !important;
  }
}

div.ui.dropdown {
  &.mg--top1 {
    margin-top: 1rem;
  }
  &.mg--top1-half {
    margin-top: 0.5rem;
  }

  &.font-body {
    font-size: $font-body !important;
    font-weight: 400 !important;
  }

  &.dropdown__selection {
    min-width: 100%;
    border-radius: 0.375em;
    border: 1px solid $dropdown-border !important;
    color: $color-font-black !important;

    &.dropdown__selection--custom {
      min-height: 68px !important;
      .label {
        max-width: 200px !important;
      }
    }
  }

  &.margin--top--bottom {
    margin: 0.8em 0;
  }

  &__sidebar {
    font-style: normal;
    font-size: 1.1429em;
    line-height: 1.5em !important;

    padding-bottom: 0.7143em !important;
    padding-top: 0.7143em !important;

    i.dropdown.icon {
      font-size: 1em;
    }

    &--inline {
      flex: auto;
    }

    &--top-bottom {
      margin-top: 2em;
      margin-bottom: 0.5em;
    }

    &--bottom {
      margin-bottom: 0.78em;
    }

    &--bottom-less {
      margin-bottom: 0.4em;
    }
  }
}

div.ui.active.visible.dropdown__selection {
  border: 1px solid $dropdown-active !important;

  div.visible.menu.transition {
    border-color: $dropdown-active !important;
  }
}

div.ui.selection.dropdown.dropdown-Dashboard {
  min-height: 0px;
  padding: 10px;
  border: 1px solid $dropdown-border;
  border-radius: 6px;
  color: $color-font-empty;

  i.dropdown.icon {
    line-height: 0px;
    top: 1em;
  }

  &.selectTime {
    min-width: 10em;

    div.text {
      font-size: $font-caption1;
      font-weight: 500;
    }
  }

  &.selectWidget {
    min-width: 13em;
    margin-bottom: 50px;

    div.text {
      font-size: $font-body;
      font-weight: 500;
    }
  }
}

//dropdown setting report
div.ui.fluid.selection.dropdown.dropdown--settingReport {
  i.dropdown.icon {
    color: $color-placeholder;
  }
}

.dropdown-pagesize-wrapper {
  margin-top: 0.2858em;
  .dropdown {
    min-width: 70px !important;
    width: 70px !important;
    margin-right: 5px;
    padding: 11px 17px !important;
    .dropdown.icon {
      margin: 0px 0px !important;
      right: -1.8em !important;
      top: -0.014286em !important;
    }
  }
}