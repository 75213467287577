.ui.basic.table {
  &__fixed-body {
    margin-bottom: 0;
    margin-top: 0;
  }

  &__fixed-header {
    margin-bottom: 0;
    thead {
      th {
        background: $table-header-background;
        font-size: $font-secondary;
        font-weight: $font-weight-bold;
        color: $table-header-color;
        padding: 10px !important;
        position: sticky;
      }

      tr:first-child > th:first-child {
        border-radius: 5px 0px 0px 0px;
      }

      tr:last-child > th:last-child {
        border-radius: 0px 5px 0px 0px;
      }
    }

    &--account {
      thead {
        th {
          background: $table-header-background;
        }
      }
      &__scroll {
        thead {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
      }
    }

  }
}

.ui.basic.table > .table__body-user {
  tr {
    &:hover {
      color: $table-row-hover;
    }
  }

  &__bodyScroll {
    height: 50px !important;
  }
  &__scroll {
    height: calc(100vh - 360px);
    overflow-y: auto;
    display: block;
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $scrollbar-background;
      border-radius: 0px !important;
    }
  }
}

.ui.basic.table > .table__body-user-role {
  tr {
    cursor: default !important;
  }
}

tr.row--userList {
  cursor: pointer;

  &:hover {
    color: $table-row-hover;
  }
}

div.fixed-table-container {
  width: 100%;
  height: calc(100vh - 320px);
  position: relative;
  padding-top: 40px;
  margin-top: 20px;
}

.fixed-table-container .header-background {
  height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 5px 5px 0px 0px;
  background: $table-header-background;
}

.fixed-table-container .fixed-table-container-inner {
  overflow-y: scroll;
  height: calc(100vh - 450px);
  // height: 80%;
  // margin-top: 10px;

  &--deviceType {
    height: calc(100vh - 360px);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-background;
    border-radius: 0px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.fixed-table-container table {
  width: 100%;
  overflow-y: scroll;
}

.fixed-table-container .th-inner {
  position: absolute;
  top: 0;
  padding: 12px;
  color: $table-header-color;
  font-size: $font-secondary;
  font-weight: $font-weight-initial;
}

.fixed-table-container td {
  border-bottom: 1px solid $table-border-bottom;
  padding: 10px;
}

tbody {
  a,td {
    color: $table-body-color;
  }

  tr:hover {
    cursor: pointer;
    color: $table-row-hover !important;

    a,td {
      color: $table-row-hover !important;
    }
  }
}

table.ui.table {
  &.table__header--graybg {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    border: none;
    border-radius: 0;

    th {
      color: $table-header-color;
      font-weight: 400;
      background: $table-header-background;

      &.HeaderCell--first {
        border-radius: 0.5em 0 0 0.5em !important;
      }

      &.HeaderCell--last {
        border-radius: 0 0.5em 0.5em 0 !important;
      }
    }
  }
}

td.table__cell--loading {
  padding-left: 1.2em;
}

.table-th__footer {
  text-align: left !important;
  border-top: none !important;
  &_grid {
    margin-top: 0.2858em !important;
  }
}