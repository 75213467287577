/* Making the label break the flow */
.floating-label {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  //z-index: 1500;
  font-size: 0.8571em !important;
  line-height: 1.2857em !important;

  &--hidden {
    visibility: hidden !important;
  }
}

/* Hide the browser-specific focus styles */
.floating-label-field {
  //color: rgba(44, 62, 80, .75) !important;
  color: black !important;
  //border-width: 0;
  z-index: 9;
  font-size: 1.1429em !important;
  line-height: 1.5000em !important;
  border: 1px solid rgba(34, 36, 38, .15) !important;
}

.floating-label-field:focus {
  outline: 0;
  box-shadow: none;
}

/* Make the label and field look identical on every browser */
.floating-label,
.floating-label-field {
  font: inherit;
  display: block;
  width: 100%;
}

.floating-label-field,
.floating-label-wrap {
  // text-align: center;
  position: relative;
  //padding-bottom: 0.5714em;

  &--top-bottom {
    margin-top: 0.9em;
    margin-bottom: 0.5714em;
  }

  &--bottom {
    margin-bottom: 0.5714em;
  }

  &--last {
    margin-bottom: 1.7143em !important;
  }
}


.floating-label-field--s3 {
  padding: 0.678571em 1em;
  transition: border-color .25s ease-in-out;
  color: rgba(255, 255, 255, .75);
  border: 1px solid rgba(255, 255, 255, .1);
  border-radius: 5px;
  background-color: transparent;

  &:focus, &:not(:placeholder-shown) {
    border-color: $color-primary!important;
  }

  &--inactive {
    color: rgba(143,143,143, 1) !important;
    &:focus, &:not(:placeholder-shown) {
      border-color: rgba(34, 36, 38, .15) !important;
    }
  }
}

.floating-label-field--s3 + .floating-label {
  position: absolute;
  top: .75em;
  left: .75em;
  display: inline-block;
  width: auto;
  margin: 0;
  padding: .75em;
  transition: transform .25s, opacity .25s, padding .25s ease-in-out;
  transform-origin: 0 0;
  color: rgba(255, 255, 255, .5);
}

.floating-label-field--s3:focus + .floating-label,
.floating-label-field--s3:not(:placeholder-shown) + .floating-label {
  z-index: 9;
  margin: 0.85em 0.25em 0.25em;
  padding: 0 0.5em;
  transform: translate(0, -2em) scale(.9);
  color: black !important;
  background-color: white;

  &.floating-label {
    color: $color-font-green !important;
  }
  &.floating-label--disabled {
    color: rgba(143,143,143, 1) !important;
  }
}

/* Common Styles */
/* Identical inputs on all browsers */
.floating-label-field--s3:not(textarea) {
  max-height: 4em;
}

.floating-label-field--s3:focus {
  &::placeholder {
    color: white !important;
  }
}


//.floating-label-field--s3:focus {
//  &::-webkit-input-placeholder {
//    color: white !important;
//  }
//
//  &:-moz-placeholder { /* Firefox 18- */
//    color: white !important;
//  }
//
//  &::-moz-placeholder {  /* Firefox 19+ */
//    color: white !important;
//  }
//
//  &:-ms-input-placeholder {
//    color: white !important;
//  }
//}
