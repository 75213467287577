.ui.menu .ui.list > .item {
  &__add-role {
    .left.content > p {
      align-items: center !important;
      align-content: center !important;
      text-align: center !important;
    }

    &:hover {
      color: $color-primary;

      .right.content .button__image > .svg-icon__check-role {
        stroke: $color-primary;
      }
    }
  }
}

.ui.list {
  &__add-role {
    height: max-content;
    overflow-y: auto;
  }
}
