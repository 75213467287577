div.ui.header.Header-page {
  font-size: $font-large-title;
  margin-top: 10px;
  margin-bottom: 10px;
}

div.ui.header.header--emptyArr {
  color: $color-font-gray;
  font-weight: normal;
  font-size: $font-title2;
}

div.ui.header.role-permit {
  font-weight: bold;
  font-style: normal;
  font-size: 1.1429em !important;
  line-height: 1.625em !important;
  margin-top: 1em !important;
  margin-bottom: 0 !important;
}

div.ui.left.aligned.header.header--filter-sort {
  font-weight: normal;
  font-size: $font-body;
  margin-left: 5px;
}

.ui.header {
  &__title1 {
    font-weight: $font-weight-bold;
    font-size: 1.7143em !important;
    line-height: 1.4167em;
    color: $color-font-black;

    &--create {
      margin-bottom: 1.7143rem;
    }

    &--sidebar {
      margin-top: 1em !important;
      margin-bottom: 0.5em !important;
    }
  }

  &__title2 {
    font-size: 1.4286em !important;
    line-height: 1.4em;
    color: $color-font-black;

    &:first-child {
      margin-top: 2.4em;
      margin-bottom: 0;
    }

    &:last-child {
      margin-top: 0;
      margin-bottom: 0.8em;
    }
  }

  &__title-sidebar {
    display: inline;
    font-size: 1.1429em !important;
    font-weight: bold;
    line-height: 1.2222em;
  }

  &__role {
    font-size: 1.429em !important;
    line-height: 1.3em;
    font-weight: bold;
    color: $color-font-white;
  }

  &__empty {
    font-size: 1em;
    line-height: 1em;
    color: $color-font-empty;
  }
}

.ui.header.header--newDashboard {
  margin: 0;
  margin-top: 10px;
  color: $color-font-empty;
  font-weight: normal;
}

.ui.header.header--removeTop {
  margin-top: 3.5px !important;
  margin: 0;
  color: $color-font-empty;
  font-weight: normal;
}

div.ui.header.header--displayInline {
  display: inline-block;
  margin-left: 20px;
  margin-top: 5px;
}

div.ui.header.dashboardTemplate {
  font-size: $font-title1;
  border-bottom: none;
}

div.ui.header.header--add-widget {
  font-size: $font-title1;
  color: $color-font-black;
  opacity: 0.48;
  margin-top: 15px;
  width: 300px;
  text-align: left;
  margin-bottom: 0px;
}

div.ui.header.header--createUser {
  margin-top: 20px;
  font-size: $font-title1;
}

div.ui.header.addWidget {
  color: $color-font-white;
  font-size: $font-caption1;
  font-weight: normal;
}

// attribute component
div.ui.header.attribute--header {
  color: $color-font-gray;
  margin-top: 15px;
}
