div.ui.modal {
  &.modal--width80p {
    width: 85%;
    padding-top: 3em;
    padding-bottom: 3em;
  }

  &.modal--width80p--withoutpadding {
    width: 85%;
  }

  &.modal--width80p--addPadding {
    width: 85%;
    padding: 3em 4em 3em 4em;
  }

  &.modal--width80p--removePadding {
    width: 85%;
    padding: 0em 3em 2em 3em;
  }

  &.modal--selectWidget {
    width: 85%;
    padding: 0em 4em 0em 4em;
  }
  &.modal--addPadding {
    padding: 3em 4em 3em 4em;
  }
}

div.modal__header--alert.header {
  &.success {
    background-color: $modal-success-color !important;
  }

  &.failed {
    background-color: $modal-failed-color !important;
  }

  border-bottom: 0 !important;
}

div.modalContent-widget.content {
  padding-top: 0px !important;
}
