label.forgotPassword-label {
  color: $label-color !important;
  float: right;
  font-size: 16px;
}

label.forgotPassword-label:hover {
  color: $label-color-hover !important;
  cursor: pointer;
}

label.verifyOTPRequestCode-label {
  color: $label-color !important;
  float: left;
  font-size: 14px;
  padding-top: 13px;
}

label.verifyOTPRequestCode-label:hover {
  color: $label-color-hover !important;
  cursor: pointer;
}


label.verifyOTPNumber-label {
  color: $label-color-hover !important;
  font-size: 16px;
}

label.description-label {
  color: $label-color;
  background-color: transparent;
  font-size: 16px;
  line-height: 26px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.ui.basic.label.label-countDashboard {
  margin-top: 5px;
  color: $color-secondary-active;
  font-size: $font-body;
  font-weight: normal;
  line-height: normal;
  padding: 3px 15px 3px 15px;

  border: 1px solid $label-border-color;
  border-radius: 8px;
}

.ui.label.label--updateTime {
  border: 1px solid $label-border-color;
  border-radius: 6px;
  padding: 9px;
  margin-left: 10px;
  color: $label-color;
  font-size: $font-caption1;
  font-weight: 500;
}

// attribute component
div.ui.label.label--key {
  border-radius: 15px;
  background-color: #efefef;
}

div.ui.label.label-telemetry {
  background: $label-tag-background;
  color: $label-tag-text;
  border-radius: 20px;
  font-weight: 500;
  font-size: 13px;
  margin-top: 3px;
}

a.ui.label.label-createTelemetry {
  background: $label-tag-background;
  color: $label-tag-text;
  font-size: 13px;
  margin: 3px;
}

label.addmargin-right {
  margin-right: 20px;
}

.ui.form .inline.field>span>label {
  font-size: .92857143em;
  font-weight: 700;
  color: rgba(0,0,0,.87);
  text-transform: none;
}