div.tab-width-height {
  .ui.menu:not(.vertical) .item {
    display: inline;
  }

  div.ui.borderless.menu {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    text-align: center;
    font-size: $font-body;
    justify-content: center;

    :hover {
      background: none;
    }

    a.item {
      color: $color-font-gray;
      width: 33.33%;

      &:hover {
        color: $color-font-black !important;
      }
    }

    a.active.item {
      background-color: $color-primary;
      box-shadow: $color-box-shadow;
      color: $color-font-white;
      border-radius: 0px 0px 8px 8px;
      font-weight: $font-weight-bold;
      padding: 20px;

      &:hover {
        color: $color-font-white !important;
      }
    }
  }
}

div.tab--select-widget {
  div.ui.borderless.menu {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    width: 100%;
  }

  .ui.segment.active.tab {
    box-shadow: none;
    border: none;
  }
}

div.tab--widget-report {
  .ui.menu:not(.vertical) .item {
    display: inline;
  }

  div.ui.borderless.menu {
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    text-align: center;
    font-size: $font-body;
    display: flex;
    justify-content: flex-end;

    :hover {
      background: none;
    }

    a.item {
      width: 15%;
      color: $color-font-gray;

      &:hover {
        color: $color-font-black !important;
      }
    }

    a.active.item {
      width: 15%;
      background-color: $color-primary;
      box-shadow: $color-box-shadow;
      color: $color-font-white;
      border-radius: 0px 0px 8px 8px;
      font-weight: $font-weight-bold;

      &:hover {
        color: $color-font-white !important;
      }
    }
  }

  div.ui.segment.active.tab {
    height: 500px;
    border: none;
    box-shadow: none;
    margin: 1em 0;
    overflow: hidden;
  }
}

// card (time serise)
div {
  &.div__tab {
    height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem;

    div.ui.segment.active.tab {
      &.tab__pane {
        padding: 0;
        border: none;
      }
    }
  }

  &.div__tab--preview {
    height: 90%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem;

    div.ui.segment.active.tab {
      &.tab__pane {
        padding: 0;
        height: 350px;
        border: none;
        box-shadow: none;

        a.active.item {
          padding: 1rem;
        }
      }
    }
  }
}
