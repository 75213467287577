div.slider.silder-img-loginpage{
    margin: auto;
    div.slider-control-centerleft,
    div.slider-control-centerright{
        display: none;
    }

    img.ui.image{
        cursor: default;
    }

   div.slider-control-bottomcenter{
       ul{
           top: 30px !important;
        li.paging-item{
            button{
                svg.paging-dot{
                    fill: $color-inactive-gray;
                    border: 5px solid $color-inactive-gray;
                    border-radius: 50%;
                }
            }
        }
           li.paging-item.active{
               button{
                   svg.paging-dot{
                    fill: $color-active-dark-green;
                    border: 7px solid $color-active-dark-green;
                    border-radius: 50%;
                   }
               }
           }
       }
   }
}