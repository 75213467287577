div.div__center--VerticallyAndHorizontally {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

div.div--left {
  text-align: left !important;
}

hr.hr--color-gray {
  border: 0.5px solid #c4c4c4;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 15px;
}

div.scrolling-horizontal {
  background-color: $scrollbar-background;
  padding-top: 0.5714em;
  padding-left: 1.1429em;
  padding-bottom: 0.5714em;
}

div.scroll-menu {
  overflow: auto;
  white-space: nowrap;
  padding-bottom: 1.1429em;

  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-background;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &.scroll-item {
    display: inline-block;
    color: $color-font-black;
    background-color: $scrollbar-background-white;
    text-align: center;
    padding: 0.5714em;
    margin-right: 0.5714em;
    text-decoration: none;
    border-radius: 0.5714em;

    &:hover {
      background-color: $scrollbar-hover;
    }
  }

  //&::-webkit-scrollbar {
  //display: none;
  //}
  //
  //-ms-overflow-style: none;
}

div.scroll-menu div {
  display: inline-block;
  color: $color-font-black;
  background-color: white;
  text-align: center;
  padding: 0.5714em;
  margin-right: 0.5714em;
  text-decoration: none;
  border-radius: 0.5714em;

  &:hover {
    background-color: #777;
  }
}

div.table__scrolling {
  overflow-y: auto;
  max-height: calc(100vh - 600px);
}

div.add-sidebar {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  &__header {
    margin-top: 1.1429em;
    margin-bottom: 1.1429em;
    height: 15%;

    &--create {
      height: auto;
      margin-bottom: 0;
    }

    &--editing {
      margin-top: 0;
      height: 30%;
    }
  }

  &__body {
    flex: 1;
    overflow: auto;
    // height: 70%;
    padding-top: 0.5714em;
    overflow-x: hidden;

    &--create {
      height: calc(100vh - 390px);
      padding-top: 0;
      padding-left: 2.2857em;
      padding-right: 2.2857em;
      margin-bottom: 0.5714em;
    }

    &--error {
      height: 50%;
      padding-top: 0;
      padding-left: 2.2857em;
      padding-right: 2.2857em;
      margin-bottom: 0.5714em;
    }

    &--editing {
      // margin-top: 35%;
      padding-top: 5%;
      height: calc(100vh - 390px);
      padding-left: 2.2857em;
      padding-right: 2.2857em;
      margin-bottom: 0.5714em;
      .row {
        padding: 0rem 0px 0px !important;
      }
    }
  }

  &__footer {
    margin-top: 1.1429em;
    height: 15%;
    text-align: center;

    &--center {
      align-self: center;
    }
  }
}

div.div__without--pd--bt {
  padding: 1em 1em 0 1em;
}

div.div__scrollbar {
  height: calc(100vh - 425px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1em 1em 0 1em;

  &--add-device {
    height: calc(100vh - 350px) !important;
    overflow-x: hidden !important;
  }

  &--add-sidebar {
    height: calc(100vh - 480px);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

div.div {
  &__header-sidebar {
    padding-bottom: 1.7143em;

    &--role-not-first {
      padding-top: 1em;
    }

    &--role {
      padding-bottom: 2em;
    }

    &--description {
      padding-bottom: 0.5em;
      text-align: left;
    }
  }

  &__inline-warp {
    display: flex;
    max-width: 100%;

    &--top-bottom {
      margin-bottom: 0.5714em;
    }

    &--w100 {
      width: 100%;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.textAlign--right {
  text-align: right;
}

.margin {
  margin: 5px !important;
}

.marginRight--10 {
  margin-right: 10px !important;
}

.marginTop--30 {
  margin-top: 30px !important;
}

.marginBottom--15px {
  margin-bottom: 15px !important;
}

.marginBottom--10px {
  margin-bottom: 10px !important;
}

.removePadding {
  padding-top: 0px !important;
}

.removePadding--bottom {
  padding-bottom: 0px !important;
}

.grid__bar--dashboard {
  margin: 0 40px !important;
}

.grid__sengment--dashboard {
  margin: 0 40px !important;
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
  display: block !important;
}

div.temp__layout--scrolling {
  overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

div.manageDashboard {
  float: right;
  display: initial;
}

.hover--greenColor {
  cursor: pointer;
  &:hover {
    color: #00c192;
  }
}

div.div--fixedBtn {
  height: calc(99vh - 410px);
  overflow-y: auto;
  padding: 1em 1em 0 1em;
}

div.div__scroll--createUser {
  height: calc(100vh - 430px);
  overflow-y: auto;
  padding: 2em 1em 0 1em;

  input, textarea {
    margin-bottom: 8px !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

//input border setting tab (report)
.input--border {
  line-height: 1.21428571em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  padding: 0.67857143em 1em;
  flex: 1 0 auto;
  vertical-align: middle;
}

div.displayColor {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 5px;
  border-radius: 0.28571429rem;
  width: 170px !important;

  &--color-value {
    height: 1.9em;
    border-radius: 0.28571429rem;
  }
}

div.block-picker.color--picker {
  margin-top: 0.813em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px !important;
  position: absolute !important;
  z-index: 2 !important;
  margin-bottom: 1.25em;
}

div.scrollbar--attribute {
  height: 12.5em;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

div.scrollbar--segment {
  height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;
}
