// directory accordion
div.dirCol__menu {
  // margin-top: -1em !important;
  height: calc(100vh - 260px);
}

div.ui {
  &.menu--noneborder {
    border: none !important;
    box-shadow: none;
    background: none;
    margin-bottom: 1em;
  }

  &.menu-path {
    margin-top: 0px;
  }

  div.item {
    &.menu--withoutpadding {
      padding: 0;
      &.word-wrap {
        width: 17vw;
        &-long {
          width: 34vw;
        }
      }
    }

    &.paddingleft--none {
      padding-left: 0;
    }

    &.removePaddingLeft-Right {
      padding: 0;
    }
  }
}

.ui.pagination.menu {
  .item {
    font-size: 1.1429em;
    line-height: 1em;
    justify-content: center;
  }

  .active.item {
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
  }

  &__account {
    margin-top: 0.2858em;
  }
}

div.ui.menu-responsive.menu {
  border: none !important;
}
